import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,InputAdornment,FormControlLabel,Checkbox,makeStyles ,InputLabel,FormGroup,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser,faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


/*
Ejemplo de autocomplete.
El array de opciones espera tener un id y un label

 <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={[{id:1,label:'pepe'},{id:2,label:'chocho'}]}
      onChange={(event, value) =>handleChangeTipoSalida(event)}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    />
*/

export const FiltrosEgresos = ({contexto})=>{
    const [tipoSeleccionado,setTipoSeleccionado] = useState(null)
    const [clase,setClase] = useState(null)
    const [estado,setEstado] = useState(null)
    const [filtrarAsignacion,setFiltrarAsignacion] = useState(false)
    const {setFiltros,filtros,periodos,conceptos,estados} = useContext(contexto)
    
    useEffect(()=>{
        // cuando el usuario incluye o quita este campo se modifica el objeto filtro
        if(tipoSeleccionado){ // ATENCIÓN #1...este es un filtro de tipo, por cada tipo de informe EGRESO, INGRESO...etc hay que agregar aquí el campo que corresponde a la primary key correspondiente, por ejemplo id_tipo_salida, id_tipo_ingreso...etc, en la función filtrar del hook se va a evaluar si es igual o diferente solo si existe ese campo, por ejemplo id_tipo_salida no va a existir en un listado de ingresos pero como no existe no lo va a evaluar, hay un IF que se asegura que solo lo evalúe si existe el campo
                              // en el futuro se pueden agregar otros tipos de informes, hay que tener el cuidado de agregar las primary keys de cada tipo de informe aquí para que funcione correctamente el filtro
            setFiltros({...filtros,id_tipo_salida:tipoSeleccionado,id_tipo_ingreso:tipoSeleccionado}) 
        }else{
            const copia = {...filtros}
            delete copia.id_tipo_salida // mismo principio que en lo explicado en  ATENCIÓN #1
            delete copia.id_tipo_ingreso // mismo principio que en lo explicado en  ATENCIÓN #1
            setFiltros({...copia})
        }
    },[tipoSeleccionado])

    useEffect(()=>{
        // cuando el usuario incluye o quita este campo se modifica el objeto filtro
        if(clase){
            setFiltros({...filtros,clase:clase})
        }else{
            const copia = {...filtros}
            delete copia.clase
            setFiltros({...copia})
        }
    },[clase])

    useEffect(()=>{
        // cuando el usuario incluye o quita este campo se modifica el objeto filtro
        if(estado){
            setFiltros({...filtros,estado:estado})
        }else{
            const copia = {...filtros}
            delete copia.estado
            setFiltros({...copia})
        }
    },[estado])

    const handleChangeTipoSalida = (value)=>{
        setTipoSeleccionado(value ? Number(value.id) : null)
    }

    const handleChangeClase = (value)=>{
        setClase(value ? value : null)
    }

    const handleChangeEstado = (value)=>{
        setEstado(value ? value?.label : null)
    }

    const handleChangePeriodo = (value)=>{// ={value.id.anio,value.id.mes}
        // cuando el usuario incluye o quita este campo se modifica el objeto filtro

        if(value){
            setFiltros({...filtros,mesString:value.id.mes,anio:value.id.anio})
        }else{
            const copia = {...filtros}
            delete copia.mesString
            delete copia.anio
            setFiltros({...copia})
        }
        
    }

    const handleChangeObservaciones = (e)=>{
        if(e.target.value){
            setFiltros({...filtros,observaciones:e.target.value})
        }else{
            const copia = {...filtros}
            delete copia.observaciones
            setFiltros({...copia})
        }
    }

    const eliminarObservaciones = ()=>{
        const copia = {...filtros}
        delete copia.observaciones
        setFiltros({...copia})
    }

    const handleChangeRetirado = (e)=>{
        if(e.target.checked){
            setFiltros({...filtros,retirado:e.target.checked})
        }else{
            const copia = {...filtros}
            delete copia.retirado
            setFiltros({...copia})
        }
    }

    const handleChangeAsignado = (e)=>{
        if(e.target.checked==true){
            setFiltros({...filtros,asignado:true})
        }else{
            setFiltros({...filtros,asignado:false})
        }/*else{
            const copia = {...filtros}g
            delete copia.retirado
            setFiltros({...copia})
        }*/
    }

    const handleChangeTodos = ()=>{
        const copia = {...filtros}
        delete copia.asignado
        setFiltros({...copia})
    }

   const handleCheckFiltrarAsignacion  = (e)=>{
        if(e.target.checked){
            setFiltros({...filtros,asignado:true})
            setFiltrarAsignacion(true)
        }else{
            setFiltrarAsignacion(false)
            eliminarAsignado();
        }
    }

    const eliminarAsignado = ()=>{
        const copia = {...filtros}
        delete copia.asignado
        setFiltros({...copia})
    }

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem',textAlign:'left' }}> 
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    //value={egreso.id_tipo_salida>0 ? tiposSalida.filter(item=>item.id_tipo_salida==egreso.id_tipo_salida)[0].nombre : ''}
                    options={conceptos.map(item=>{return {id:item.id,label:item.nombre}})}
                    onChange={(event, value) => handleChangeTipoSalida(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por concepto" />}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    //value={egreso.id_tipo_salida>0 ? tiposSalida.filter(item=>item.id_tipo_salida==egreso.id_tipo_salida)[0].nombre : ''}
                    options={['Gira','Campo']}
                    onChange={(event, value) => handleChangeClase(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por categoría" />}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                     <TextField fullWidth 
                        inputProps={{maxLength: 100}} 
                        InputProps={{
                            startAdornment: filtros?.observaciones==undefined ? null : <InputAdornment position="start">
                                      <FontAwesomeIcon title='Observaciones' className='' icon={faWindowClose} onClick={eliminarObservaciones}/>
                            </InputAdornment>
                            }}
                        id="dp-monto" 
                        value={filtros?.observaciones || ''} 
                        onChange={handleChangeObservaciones}
                       label="Observaciones" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    options={periodos}
                    onChange={(event, value) => handleChangePeriodo(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por período" />}
                    />
                </Grid>
                {estados && <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-estados"
                    options={estados.map(item=>{return {id:item.id,label:item.nombre}})}
                    onChange={(event, value) => handleChangeEstado(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por estado" />}
                    />
                </Grid>}
                {/* {!estados && <FormControlLabel control={<Switch onChange={handleChangeRetirado} />} label={`Retirado`} />} */}
                {estados && <Box>
                    <Box>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={filtrarAsignacion} onChange={handleCheckFiltrarAsignacion} />} label="Filtrar por asignación"/>
                        </FormGroup> 
                    </Box>
                    <FormControlLabel disabled={filtrarAsignacion==false} control={<Switch checked={filtros?.asignado==true}  onChange={handleChangeAsignado} />} label={`Asignado`} />
               </Box>}
                
                

            </Grid>
        </Box>
            
}

