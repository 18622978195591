import React, {useContext,useEffect,useState,useRef} from 'react'
import {TextField, Grid, Box,Typography,Paper,Button,makeStyles ,FormControlLabel,Switch,Chip,InputAdornment,IconButton,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import { AlertDialog } from '../../componentes/MUI/AlertDialog';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { getBottomNavigationActionUtilityClass } from '@mui/material';
import Swal from 'sweetalert2';
import {NuevoDonante} from './NuevoDonante'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {CheckInputCelular} from './CheckInputCelular'

const regex_solo_numeros = /^[0-9\b]+$/;
const regex_telefono = /^[0-9-+()\b]+$/;

export const DatosDonantes = ({contexto,procesarCancelar})=>{
    const {movimiento,esCelular,donantes,buscarDonantes,nuevoDonante,donanteString,borrarDonante} = useContext(contexto)
    const [altaDonante,setAltaDonante]= useState(false)

    const seleccionarDonante = (id,descripcion)=>{
        nuevoDonante(id,descripcion)
    }
    
    return <Box>
        {movimiento.id_donante && <Paper style={{marginTop:'1rem',padding:'0.5rem'}} variant='outilined' elevation={5} padding={2}>
            <Chip label="Donante" color="primary" variant="outlined" />
            <Divider sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}/>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Typography variant='body2'>
                    {donanteString}
                </Typography>
                <Button title='Borrar donante' onClick={borrarDonante}><DeleteOutlineIcon/></Button>
            </Box>
        </Paper>}
        <AlertDialog open={!movimiento.id_donante} // mantengo abierto el diálogo mientras no haya un donante seleccionado, cierro solo si o bien eligió un donante de la lista o si dio de alta un nuevo donante
                    titulo='Primero busque un donante en la lista' 
                    texto='Puede buscar combinando nombre, apellido e institución (si el donante es iglesia). Si no encuentra el donante en la lista puede crear uno nuevo' 
                    procesarCancelar = {procesarCancelar}
                    error={''}>
                <Listado seleccionarDonante={seleccionarDonante} 
                         donantes={donantes} 
                         setAltaDonante={setAltaDonante}
                         esCelular={esCelular}
                         contexto={contexto}/>
        </AlertDialog>
        {altaDonante && <NuevoDonante contexto={contexto}  finalizarAlta={()=>setAltaDonante(false)} procesarCancelar={()=>setAltaDonante(false)}/>}
    </Box>
}

const Listado =({seleccionarDonante,donantes,setAltaDonante,contexto,esCelular})=>{
    const [datos,setDatos] = useState({particular:true,nombre:'',apellido:'',institucion:''})
    const [similitudes,setSimilitudes] = useState([])
    const [mensajeError,setMensajeError] = useState(null)
    const [mensajeResultado,setMensajeResultado] = useState(null)
    const [tieneFoco,setTieneFoco]=useState('')

    useEffect(()=>{
        if(validarDatos()===true){
            setMensajeResultado(null)
        }else{
            setSimilitudes([])
            setMensajeResultado(null)
        }
    },[datos.apellido,datos.nombre,datos.institucion,datos.particular])

    useEffect(()=>{
        setMensajeError(validarDatos())
     },[datos])

    const handleChangeParticular = (e)=>{
        setDatos({...datos,particular:e.target.checked ? false : true, institucion:'',denominacion:''})
    }

    const handleChangeNombre = (e)=>{
        setDatos({...datos,nombre:e.target.value.toUpperCase().trim()})
    }

    const handleChangeApellido = (e)=>{
        setDatos({...datos,apellido:e.target.value.toUpperCase().trim()})
    }

    const handleChangeInstitucion = (e)=>{
        setDatos({...datos,institucion:e.target.value.toUpperCase()})
    }


    const validarDatos = ()=>{

        if(datos.particular){
            if(datos.nombre.trim()=='' && datos.apellido.trim()=='' ){
                return ('Falta ingresar el nombre o apellido del donante')
            }
    
          
        }else{

            return true // El nombre de la institución no es obligatorio porque el misionero a veces no sabe el dato 16/01/25

            if(datos.institucion.trim()==''){
                return('Falta ingresar el nombre de la institución')
            }
    
            if(datos.institucion.trim().length<3){
                return('El nombre de la institución es demasiado breve')
            }
        }

            return true

    }

    const buscarSimilitudes = (param)=>{

        setMensajeResultado(null)

        if(datos.particular){
            if(datos.nombre.trim()!='' || datos.apellido.trim()!=''){
                const listado = donantes
                .filter(item=>item.nombre && item.apellido)
                .filter(item=>item.apellido.toUpperCase().trim().includes(datos.apellido) && item.nombre.toUpperCase().trim().includes(datos.nombre))
                setSimilitudes(listado)
                setMensajeResultado(listado.length==0 ? 'No se encontraron coincidencias' : listado.length==1 ? 'Se encontró 1 coincidencia' : `Se encontraron ${listado.length} coincidencias`)
           }else{
                setSimilitudes([])
           }
        }else{

            // El nombre de la institución no es obligatorio porque el misionero a veces no sabe el dato 16/01/25
            // if(datos.institucion.trim()!=''){
            if(datos.nombre.trim()!='' || datos.apellido.trim()!=''){

                const listado = donantes
               .filter(item=>item.institucion)
               //.filter(item=>item.institucion.toUpperCase().trim().includes(datos.institucion) && item.nombre.toUpperCase().trim().includes(datos.nombre)  && item.apellido.toUpperCase().trim().includes(datos.apellido))
                .filter(item=>(!item.institucion || item.institucion.toUpperCase().trim().includes(datos.institucion)) && item.nombre.toUpperCase().trim().includes(datos.nombre)  && item.apellido.toUpperCase().trim().includes(datos.apellido))
                setSimilitudes(listado)
                setMensajeResultado(listado.length==0 ? 'No se encontraron coincidencias' : listado.length==1 ? 'Se encontró 1 coincidencia' : `Se encontraron ${listado.length} coincidencias`)
           }else{
                setSimilitudes([])
           }
        }
      
    }

    const handlekeypress = (e)=>{
        if(e.key=='Enter'){
            if(validarDatos()===true){
                buscarSimilitudes()
            }
        }
    }
    return  <Box onKeyPress={handlekeypress}>
                    <Box sx={{position:esCelular() ? 'relative' :'sticky',top:0,background:'white',zIndex:1000}}>
                            {mensajeError!=true && <Alert severity="error">{mensajeError}</Alert>}
                    </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{marginTop:'1rem',display:'flex'}}>
                                            <TextField autoComplete='off' 
                                                autoFocus={!esCelular()} 
                                                fullWidth 
                                                inputProps={{maxLength: 200}} 
                                                id="dp-nombre"
                                                value={datos.nombre} 
                                                onChange={handleChangeNombre} 
                                                label="Nombre *" 
                                                onInput={(e)=>setTieneFoco(e.target.id)}
                                                variant="outlined" />
                                                <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-nombre'}/> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{marginTop:'1rem',display:'flex'}}>
                                            <TextField autoComplete='off' onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 200}} id="dp-apellido" value={datos.apellido} onChange={handleChangeApellido} label="Apellido *" variant="outlined" />
                                            <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-apellido'}/> 
                                        </Box>
                                    </Grid>
                                </Grid>
                                {!datos.particular && <Grid item xs={12}>
                                    <Alert sx={{marginBottom:'0.5rem'}} severity="info">{`Cuando el donante es una iglesia el nombre y apellido corresponden al pastor de la misma`}</Alert>
                                </Grid>}
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12} sm={6}>
                                        <FormControlLabel control={<Switch checked={!datos.particular} onChange={handleChangeParticular} />} label={`El donante es una iglesia`} />
                                </Grid>
                                {!datos.particular && <Grid item xs={12}  sm={6}>
                                    <Box sx={{marginTop:'1rem',display:'flex'}}>
                                        <TextField  onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 200}} id="dp-institucion" value={datos.institucion} onChange={handleChangeInstitucion} label="Institución/Iglesia" variant="outlined" />
                                        <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-institucion'}/> 
                                    </Box>
                                </Grid>}
                            </Grid>
                 {validarDatos()===true &&  <Grid container spacing={2} alignItems='center'> 
                                <Grid item xs={12}>
                                        <Button fullWidth
                                            onClick={buscarSimilitudes} 
                                            variant='contained' color='primary' size='large'>
                                                Buscar
                                        </Button>
                                </Grid> 
                            </Grid>}
                            {mensajeResultado && <>
                                <Divider sx={{marginTop:'1rem'}}/>
                                <Typography align="center" variant='h6'>
                                       {mensajeResultado}
                                </Typography></>}
                            {similitudes.length>0 && <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {similitudes.map(item=> <><ListItem sx={{background:"#bbdefb"}}>
                                        <ListItemButton onClick={()=>seleccionarDonante(item.id_donante,item.donante)}>
                                            <ListItemAvatar>
                                            <Avatar>
                                                <PersonIcon/>
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={`${item.apellido}, ${item.nombre}`} 
                                                secondary={<>
                                                        {esIglesia(item) && <p>{item.institucion}</p>}
                                                        <Divider textAlign="left"><b>Dirección</b></Divider>
                                                        <p>{item.direccion}</p>
                                                        <Divider textAlign="left"><b>Teléfono</b></Divider>
                                                        <p>{item.telefono}</p>
                                                        </>}/>
                                                
                                    </ListItemButton>
                                    </ListItem>
                                    <Divider sx={{marginTop:'1rem'}}/>
                                    </>)}
                            </List>}   
                            {mensajeResultado && <Button fullWidth
                                            onClick={()=>setAltaDonante(true)} 
                                            variant='outlined' color='primary' size='large'>
                                                <TextoSegunResultados resultados={similitudes}/>
                            </Button>}         
            </Box>

}

const Resultado = ({resultado,setAltaDonante})=>{
    return <Box padding={2}>
            <Paper variant='outilined' elevation={5} padding={2}>
                <p>reemplazar esto por un alert como el que usamos en el tema del compromiso</p>
                <Typography variant='body2'>
                    {resultado==1? 'Encontramos 1 similitud' : resultado > 0 ? `Encontramos ${resultado} similitudes` : 'No encontramos similitudes'}  
                </Typography> 
                {resultado > 0 && <Typography style={{marginTop:'0.5rem'}} variant='h6'>
                        Por favor verifique si el donante aparece en la lista
                </Typography>} 
                <Button 
                    onClick={()=>setAltaDonante(true)} 
                    variant='contained' color='primary' size='large'>
                        Crear un nuevo donante
                </Button>
            </Paper>
        </Box>
}

const CrearDonante = ({datos,buscarDonantes,seleccionarDonante})=>{
    const [procesando,setProcesando] = useState(false)
    const [confirmar,setConfirmar] = useState(false)
    const detalles = [`Nombre: ${datos.nombre}`,`Apellido: ${datos.apellido}`,`Institución: ${datos.institucion}`,`Ubicación: ${datos.direccion} - ${datos.localidad} (${datos.pais})`]
    const procesar = (confirmacion) =>{

        if(confirmacion===true){ // para controlar si cerró haciendo click en SI y no afuera del dialog
            crearNuevoDonante()
            .catch(err=>{alert(err)
                    })
            setConfirmar(false)
        }else{ 
            setConfirmar(false)
        }
    }

    const crearNuevoDonante = async ()=>{

        try{

            setProcesando(true)
          
            const respuesta = await Axios.post(`/api/tablasgenerales/donante`,{...datos})
        
            const id_nuevo_donante = respuesta.data.id_donante
            if(id_nuevo_donante){
                setTimeout(() => {
                    setProcesando(false)
                    buscarDonantes()
                    seleccionarDonante(id_nuevo_donante,`${datos.apellido}, ${datos.nombre} ${datos.institucion.length>0 ? ` (${datos.institucion}) `:''}`)
                }, 1000);
    
                Swal.fire({
                    html:`Se correctamente el nuevo donante id= ${respuesta.data.id_donante}`,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                })
            }else{
                Swal.fire({
                    html:'No se creó el donante, por favor informe a la oficina del DNM',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }
          

        }catch(err){
            setProcesando(false)
            if(err.response?.data?.message){                
                Swal.fire({
                    html:err.response.data.message,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }else{
                Swal.fire({
                    html:"Se produjo un error al grabar el donante",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }
        }

    }

    return  <>
          <AlertDialog open={confirmar} procesarResultado={procesar} procesarCancelar={procesar} titulo={`¿Confirma al nuevo donante?`} texto="">
                    {detalles.map((item)=><p style={{padding:'0.25rem'}}>{item}</p>)}
          </AlertDialog>
          <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                <Button 
                    onClick={()=>setConfirmar(true)} 
                    variant='contained' color='primary' size='large'>
                    Crear el nuevo donante
                </Button>
          </Box>
          
    </>
}

function validarEmail(email){
 
    //http://www.regular-expressions.info/email.html
     const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
     
     return re.test(String(email).toLowerCase().trim());
}

const esIglesia = (item)=>{
    return item.institucion!=null && item.institucion.trim()!=''
}

const TextoSegunResultados = ({resultados})=>{
    if(resultados.length==0){
        return 'Crear un nuevo donante'
    }else{
        return <Box>
            <span>No encontré mi donante en la lista</span>
            <p>Deseo crear un nuevo donante</p>
        </Box>
    }
}