import React, {useContext,useState,useEffect} from 'react'
import {CircularProgress, Button, Box ,Typography,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {GenericCard} from '../../componentes/MUI/GenericCard'
import {SelectTiposMovimiento} from '../componentesComunes/SelectTiposMovimiento'
import {SelectMes} from '../componentesComunes/SelectMes'
import {SelectAnio} from '../componentesComunes/SelectAnio'
import {InputMonto} from '../componentesComunes/InputMonto'
import {RadioGiraCampo} from '../componentesComunes/RadioGiraCampo'
import {ButtonEnviar} from '../componentesComunes/ButtonEnviar'
//import {egresoContext} from '../ingresos/contexto/ingresoContext'
import { ListadoFormularios } from '../componentesComunes/ListadoFormularios';
import { AlertDialog } from '../../componentes/MUI/AlertDialog';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import {useContextosSegunTipo} from '../hooksComunes/useContextosSegunTipo'
import {AutoCompleteDonantes} from '../componentesComunes/AutoCompleteDonantes'
import {DatosDonantes} from '../componentesComunes/DatosDonantes'
import {TablaTest} from '../componentesComunes/TablaTest'
import {InputObservaciones} from '../componentesComunes/InputObservaciones';
import {CheckRetirado} from '../componentesComunes/CheckRetirado';
import {SelectEstadoIngreso} from '../componentesComunes/SelectEstadoIngreso';

export const Movimientos = ({usuario,tipo})=>{
    const {contexto} = useContextosSegunTipo(tipo)     
//    const {egreso,datosOk,procesando,finalizado,reinicializar} = useContext(egresoContext)
    const {movimiento,
                datosOk,
                procesando,
                finalizado,
                reinicializar,
                descripcion,
                solicitarDonante,
                solicitarCategoria} = useContext(contexto)
    const [comenzar,setComenzar] = useState(false)

    useEffect(()=>{
        if(finalizado){
                setComenzar(false)
        }
    },[finalizado])

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    const cancelar = ()=>{setComenzar(false);reinicializar()}

    if (procesando){
        return  <Box style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
                         <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
                                <CircularProgress title='Procesando...'/>
                                <Typography variant='h6'>
                                        Procesando...
                                </Typography>
                        </Box>
             </Box>
     }

     return  <Box sx={{display:'flex',justifyContent:'center',flexDirection:'column'}}>

        <AlertDialog open={comenzar} titulo={`Nuevo movimiento de ${descripcion.toLowerCase()}`} procesarCancelar={cancelar} error={datosOk()[1]} >
                       <Box>    
                               
                                <SelectTiposMovimiento contexto={contexto}/> 

                                {solicitarDonante && <DatosDonantes procesarCancelar={reinicializar} contexto={contexto}/>}

                                { (!solicitarDonante || (solicitarDonante && movimiento.id_donante)) &&<>

                                <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                                        <SelectMes  contexto={contexto}/>
                                        <SelectAnio  contexto={contexto}/>
                                </Box>

                                {(solicitarDonante || solicitarCategoria) &&<RadioGiraCampo  contexto={contexto}/>}
                                
                                <InputMonto  contexto={contexto}/>

                                <InputObservaciones  contexto={contexto}/>
                                
                                {/* <CheckRetirado contexto={contexto}/> */}

                                <SelectEstadoIngreso contexto={contexto}/>

                                {datosOk()[0] && <ButtonEnviar  contexto={contexto}/> } 

                                </>}
                       </Box>
              </AlertDialog>
        <GenericCard
                            id='tl-contacto' 
                            mostrar={true}
                            estilo={{display:'flex',justifyContent:'center'}}
                            column>
                                {!comenzar && <Box sx={{display:'flex',justifyContent:'center'}}>
                                        <Button 
                                        onClick={()=>setComenzar(true)} 
                                        variant='contained' color='primary' size='large'>
                                                Informar un nuevo movimiento
                                        </Button>
                                </Box>}
                  
                    <ListadoFormularios  contexto={contexto}/>
                    {false && <TablaTest/>}
            </GenericCard>
              </Box>
}